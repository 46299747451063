<template>
  <!-- 数据在本页 -->
  <div>
    <div class="caseDataInfo">
      <div class="infoOne" v-if="isShow">
        <el-form
          :model="caseDataInfo"
          :rules="rulesCaseDataInfo"
          ref="rulesCaseDataInfo"
          label-width="120px"
        >
          <el-form-item label="案例名称:" prop="planTitle">
            <el-input v-model="caseDataInfo.planTitle" style="width: 50%" />
          </el-form-item>
          <el-form-item label="案例类型:" prop="softwareHardwareList">
            <!-- <dic-checkbox-group
              :code.sync="caseDataInfo.softwareHardwareList"
              type-code="024"
            /> -->
            <el-checkbox-group  v-model="caseDataInfo.softwareHardwareList">
              <el-checkbox label="商业案例"></el-checkbox>
              <el-checkbox label="技术类型"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="案例图片:">
            <UpLoadImage
              @upLoadImgList="lookClickTemp"
              @upOldLoadImgList="saveClickTemp"
              :upLoadImgList.sync="caseDataInfo.imgPath"
              :upOldLoadImgList.sync="caseDataInfo.temporarypathImg"
            />
          </el-form-item>
          <el-form-item label="案例分享图:">
            <UpLoadImage
              @upLoadImgList="lookClick"
              @upOldLoadImgList="saveClick"
              :upLoadImgList.sync="caseDataInfo.shareImage"
              :upOldLoadImgList.sync="caseDataInfo.simgpathImg"
            />
          </el-form-item>
          <el-form-item label="案例地点:">
            <cascader-area
              :country="caseDataInfo.planCountry"
              :province="caseDataInfo.planProvince"
              :city="caseDataInfo.planCity"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </el-form-item>
          <el-form-item label="具体地址:" prop="planAddress">
            <el-input v-model="caseDataInfo.planAddress" style="width: 50%" />
          </el-form-item>
          <el-form-item label="案例视频:" prop="videoResults">
            <div class="cover">
              <!-- videos是返回的路径什么的  videoResults是带的id -->
              <div class="img_box" v-if="caseDataInfo.video">
                <uploadVideo
                  :videos="caseDataInfo.video"
                  :videosId="caseDataInfo.videoResults"
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
              </div>
              <div class="img_box" v-else>
                <uploadVideo
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
              </div>
              <!-- <div v-if="caseDataInfo.videos">缩略图</div>
              <div v-if="caseDataInfo.videos" class="thumbnail">
                <div class="thumbnail_box">
                  <img :src="caseDataInfo.videos[0].coverImage" alt="" />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：4MB</div>
                  <div>展示位置：首页</div>
                </div>
                <div class="thumbnail_box">
                  <img :src="caseDataInfo.videos[0].coverImage" alt="" />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：5MB</div>
                  <div>展示位置：文章详情页</div>
                </div>
              </div> -->
            </div>
          </el-form-item>
          <el-form-item label="案例简介:" prop="planDescribe">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 20 }"
              v-model="caseDataInfo.planDescribe"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="客户痛点:" prop="customerPainPoint">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="caseDataInfo.customerPainPoint"
                  style="width: 50%"
                />
              </el-form-item>
              <el-form-item label="解决方案:" prop="customerSolution">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="caseDataInfo.customerSolution"
                 style="width: 50%"
                />
              </el-form-item>
              <el-form-item label="案例成果:" prop="planOutcome">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="caseDataInfo.planOutcome"
                  style="width: 50%"
                />
              </el-form-item>
              <el-form-item style="width: 60%;" label="案例附件:" prop="planFile">
                <div
                  class="name_form"
                  style="
                    width: 90%;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <el-input
                    v-model="caseDataInfo.planFile"
                    placeholder="请上传产品附件"
                  />
                  <el-upload
                    action
                    class="upload-demo"
                    ref="upload"
                    :show-file-list="false"
                    :before-upload="beforeUploadprods"
                    :http-request="
                      (res) => {
                        return uploadprods(res, planIndex);
                      }
                    "
                  >
                    <el-button
                      style="position: relative; left: -76px"
                      slot="trigger"
                      size="mini"
                      type="primary"
                      plain
                      >上传附件</el-button
                    >
                  </el-upload>
                  </div>
                  </el-form-item>
          <!-- 最多新增3个 -->
          <!-- <div
            class="listData"
            v-for="(item, planIndex) in caseDataInfo.planCustomerList"
            :key="planIndex"
            style="width: 90%"
          >
            <div class="data" style="width: 60%">
              <el-form-item label="客户痛点:" prop="customerPainPoint">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="item.customerPainPoint"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="解决方案:" prop="customerSolution">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="item.customerSolution"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="案例成果:" prop="planOutcome">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  v-model="item.planOutcome"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="案例附件:" prop="planFile">
                <div
                  class="name_form"
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <el-input
                    v-model="item.planFile"
                    placeholder="请上传产品附件"
                  />
                  <el-upload
                    action
                    class="upload-demo"
                    ref="upload"
                    :show-file-list="false"
                    :before-upload="beforeUploadprods"
                    :http-request="
                      (res) => {
                        return uploadprods(res, planIndex);
                      }
                    "
                  >
                    <el-button
                      style="position: relative; left: -76px"
                      slot="trigger"
                      size="mini"
                      type="primary"
                      plain
                      >上传附件</el-button
                    >
                  </el-upload>
                </div>
                <span style="color: #909399"
                  >支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span
                >
              </el-form-item>
            </div>
            <div
              style="
                display: flex;
                font-size: 24px;
                margin-left: 20px;
                margin-top: 6%;
                cursor: pointer;
              "
            >
              <span
                v-if="planIndex == 0"
                style="color: #4e93fb"
                @click="addvalue"
                ><i class="el-icon-circle-plus" style="font-size: 24px"></i
              ></span>
              <span
                v-if="planIndex > 0"
                style="color: #ff7575"
                @click="delvalue(planIndex)"
                ><i class="el-icon-delete" style="font-size: 24px"></i
              ></span>
            </div>
          </div> -->
          <el-form-item label="案例网址:">
            <!-- <el-input
              v-model="caseDataInfo.planUrl"
              class="lager-input"
            /> -->
            <el-link
              :href="caseDataInfo.planUrl"
              target="_blank"
              :underline="false"
              >{{ caseDataInfo.planUrl }}</el-link
            >
            <i
              class="jr-iconjr-icon-edit1"
              style="margin-left: 24px"
              @click="isLinkShow = true"
            ></i>
            <div class="input_button" v-if="isLinkShow">
              <el-input v-model="caseDataInfo.planUrl" style="width: 50%" />
              <el-button
                type="primary"
                style="height: 40px; margin-left: 24px"
                @click="isLinkShow = false"
                >保存</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <div class="ess_people" v-if="projectDataType">
          <div class="ess_people_list">
            创建人：{{ caseDataInfo.createName }}
          </div>
          <div class="ess_people_list">修改人：{{ caseDataInfo.modified }}</div>
          <div class="ess_people_list">
            创建时间：{{ caseDataInfo.createTime }}
          </div>
          <div class="ess_people_list">
            修改时间：{{ caseDataInfo.modification }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
// 引入上传附件数据
import cos from "../../utils/cos.js";
import UpLoadImage from "../Product/UpLoadImage";
import DicRadioGroup from "@/components/DicRadioGroup.vue";

import {
  addCaseData,
  updateCaseData,
  findReport,
  findCompany,
  findPlan,
} from "@/api/caseData";
import CascaderArea from "@/components/CascaderArea.vue";
import DataTime from "@/components/DataTime.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";

import uploadVideo from "../../components/uploadVideo.vue";
export default {
  name: "CaseDataInfo",
  components: {
    CascaderArea,
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
    uploadVideo,
  },
  props: {
    caseDataInfo: {
      type: Object,
    },
  },
  watch: {
    //-------------
    "caseDataInfo.softwareHardwareList": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.caseDataInfo.softwareHardwareList = newVal||[] ;
          // if( newVal){
          //   this.caseDataInfo.softwareHardwareList = newVal ;
          // }else{

          // } this.caseDataInfo.softwareHardwareList=[]

        }
      },
    },
    // "caseDataInfo.planCustomerList": {
    //   handler(newVal, oldVal) {
    //     if (newVal != oldVal) {
         
    //       this.caseDataInfo.planCustomerList = newVal ;
    //       // if( newVal){
    //       //   this.caseDataInfo.softwareHardwareList = newVal ;
    //       // }else{

    //       // } this.caseDataInfo.softwareHardwareList=[]

    //     }
    //   },
    // },
    
  },
  data() {
    return {
      isLinkShow: false,
      isShow: true,
      // optionsAssociation: [],
      // caseDataInfo: {
      //   planTitle: "", // 案例名称
      //   shareImage: [], //案例分享图
      //   simgpathImg: [], // 保存的案例分享图
      //   // imgpaths: [], //案例封面图
      //   // simgpathImg: [], // 保存的案例图片
      //   planCountry: "", // 案例地点国家
      //   planProvince: "", // 案例地点省份
      //   planCity: "", // 案例地点市
      //   planAddress: "", //具体地址
      //   video: null, // 案例视频
      //   videos: null, //案例视频的id
      //   planDescribe: "", // 案例简介
      //   planUrl: "", // 案例网址
      //   softwareHardware: "", // 案例类型
      //   softwareHardwareList: [], // 案例类型
      //   imgPath: [], // 案例图片
      //   temporarypathImg: [], //保存的案例图片
      //   // 遍历四个 (案例客户)
      //   planCustomerList: [
      //     {
      //       customerPainPoints: "", //客户痛点
      //       customerSolutions: "", //解决方案
      //       planOutcomes: "", //案例成果
      //       planFile: "", //案例附件
      //     },
      //   ],
      //   // =======================================================

      //   // year: null, // 年
      //   // month: null, // 月
      //   // wisdomLabels: [], // 领域类别
      //   // wisdomLabelsChildren: [],
      //   // labels: [], // 技术类别
      //   // labelsChildren: [],
      //   // sectorss: [], // 行业类别
      //   // sectorssChildren: [],
      //   // companyCode: "", // 企业名称
      //   // productType: "", // 产品类型
      //   // productName: "", // 产品名称
      //   // companyCustomerName: "", // 客户企业
      //   // planKeywords: [], // 案例关键字
      //   // scene: "", // 应用场景
      //   // association: "", // 案例关联方案
      //   // imgpath: [], // 查看的案例图片
      //   // reportId: null,
      //   // antistop: "",
      // },
      inputVisible: false,
      inputValue: "",
      imageList: [],
      options: [],
      options1: [],
      rulesCaseDataInfo: {
        planTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
        softwareHardwareList: [
          { required: true, message: "请选择案例类型", trigger: "change" },
        ],
      },
      query: {
        judgeArray: [
          {
            label: "软件",
          },
          {
            label: "软硬件",
          },
        ],
      },
      loading: false,
    };
  },

  computed: {
    // 0 新增 1 编辑
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    // 数据唯一id
    editInfo() {
      return Number(this.$route.query.editInfo);
    },
  },
  // watch: {
    // "caseDataInfo.id": {
    //   handler(newVal) {
    //     if (this.caseDataInfo && newVal) {
    //       setTimeout(() => {
    //         this.isShow = true;
    //       }, 200);
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  // },
  created() {
    // if (this.projectDataType) {
    //   // this.search();
    //   this.findScheme();
    //   setTimeout(() => {
    //     this.isShow = true;
    //   }, 500);
    // } else {
    //   this.isShow = true;
    // }
    // if(this.projectDataType==0){
    //   console.log( this.caseDataInfo.planCustomerList);
      
    //   this.caseDataInfo.planCustomerList=[
    //   {
    //   customerPainPoint: "", //客户痛点
    //   customerSolution: "", //解决方案
    //   planOutcome: "", //案例成果
    //   planFile: "", //案例附件
    // },
    //   ]
    // }
    
   
  },

  methods: {
  
    // 数据处理
    handleDta() {
      // 案例类型
      // if (this.caseDataInfo.softwareHardwareList) {
      //   this.caseDataInfo.softwareHardwareList =
      //     this.caseDataInfo.softwareHardwareList.join(",");
      // }
      // 领域
      // if (this.caseDataInfo.wisdomLabelsChildren) {
      //   this.caseDataInfo.wisdomLabels = this.caseDataInfo.wisdomLabels.concat(
      //     this.caseDataInfo.wisdomLabelsChildren
      //   );
      // }
      // 技术
      // if (this.caseDataInfo.labelsChildren) {
      //   this.caseDataInfo.labels = this.caseDataInfo.labels.concat(
      //     this.caseDataInfo.labelsChildren
      //   );
      // }
      // 行业
      // if (this.caseDataInfo.sectorssChildren) {
      //   this.caseDataInfo.sectorss = this.caseDataInfo.sectorss.concat(
      //     this.caseDataInfo.sectorssChildren
      //   );
      // }
      // 领域
      // this.caseDataInfo.wisdomLabelsChildren = [
      //   ...new Set(this.caseDataInfo.wisdomLabelsChildren),
      // ];
      // 技术
      // this.caseDataInfo.labelsChildren = [
      //   ...new Set(this.caseDataInfo.labelsChildren),
      // ];
      // 行业
      // this.caseDataInfo.sectorssChildren = [
      //   ...new Set(this.caseDataInfo.sectorssChildren),
      // ];
    },
    // 新增四个字段
    addvalue() {
      if (this.caseDataInfo.planCustomerList.length > 2) {
        this.$message.warning("最多新增三个");
      } else {
        this.caseDataInfo.planCustomerList.push({
          customerPainPoint: "", //客户痛点
          customerSolution: "", //解决方案
          planOutcome: "", //案例成果
          planFile: "", //案例附件
        });
      }
    },
    // 删除四个字段
    delvalue(index) {
      this.caseDataInfo.planCustomerList.splice(index, 1);
    },
    //上传行业动态pdf (上传文件之前的钩子，参数为上传的文件)
    async beforeUploadprods(file) {
      this.file = file;
      // 拿到上传文件的名字
      this.filename = file.name;
      // 要求上传格式
      let format = file.name.split(".")[1];
      // 限制上传的要求
      let formatList = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];
      // 判断上传的文件是否符合要求
      const extension = formatList.indexOf(format);
      if (extension == -1) {
        this.$message.warning("上传模板不支持此格式");
        return;
      }
      // 异步读取文件机制
      let reader = new FileReader();
      // 读取文件内容，结果用data:url的字符串形式表示
      reader.readAsDataURL(file);

      let that = this;
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象，并生成data URl(base64编码)
      reader.onload = function () {
        that.fileData = reader.result;
      };
      return false;
    },
    //上传行业动态
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: "ginseng-wisdom-1300257084" /* 填写自己的 bucket，必须字段 */,
          Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
          Key:
            "system_img/" +
            params.file
              .name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
        })
        .then((res) => {
          if (res.statusCode === 200) {
            // vue2无法监控数组的改变
            // this.$set(this.caseDataInfo.planCustomerList, index, res.Location);
            // 这里不是数组,是数组对象中的某个属性
            this.caseDataInfo.planFile = res.Location;
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
        });
    },
    //视频上传
    uploadOneImgvideo(val) {
      this.caseDataInfo.videoResults = val;
    },
    removeImgvideo(val) {
      this.caseDataInfo.videoResults = val;
    },
    //-----------------------------------------------------
    lookClick(val) {
      this.caseDataInfo.simgpathImg = val;
    },
    saveClick(val) {
      this.caseDataInfo.shareImage = val;
    },
    lookClickTemp(val) {
      this.caseDataInfo.temporarypathImg = val;
    },
    saveClickTemp(val) {
      this.caseDataInfo.imgPath = val;
    },
    handleClose(tag) {
      this.caseDataInfo.planKeywords.splice(
        this.caseDataInfo.planKeywords.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.caseDataInfo.planKeywords.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // updateCode(val, tag) {
    //   switch (tag) {
    //     case "领域":
    //       // this.caseDataInfo.wisdomLabelsChildren = val;
    //       break;

    //     case "技术":
    //       // this.caseDataInfo.labelsChildren = val;
    //       break;

    //     case "行业":
    //       // this.caseDataInfo.sectorssChildren = val;
    //       break;

    //     default:
    //       break;
    //   }
    // },
    // dateClick(year, month) {
    //   this.caseDataInfo.year = year;

    //   this.caseDataInfo.month = month;
    // },
    // 保存
    // saveItem() {
    //   this.$refs.rulesCaseDataInfo.validate(async (valid) => {
    //     if (valid) {
    //       let res;
    //       // if (this.caseDataInfo.wisdomLabelsChildren) {
    //       //   this.caseDataInfo.wisdomLabels =
    //       //     this.caseDataInfo.wisdomLabels.concat(
    //       //       this.caseDataInfo.wisdomLabelsChildren
    //       //     );
    //       // }
    //       // if (this.caseDataInfo.labelsChildren) {
    //       //   this.caseDataInfo.labels = this.caseDataInfo.labels.concat(
    //       //     this.caseDataInfo.labelsChildren
    //       //   );
    //       // }
    //       // if (this.caseDataInfo.sectorssChildren) {
    //       //   this.caseDataInfo.sectorss = this.caseDataInfo.sectorss.concat(
    //       //     this.caseDataInfo.sectorssChildren
    //       //   );
    //       // }

    //       if (this.caseDataInfo.softwareHardwareList) {
    //         this.caseDataInfo.softwareHardware =
    //           this.caseDataInfo.softwareHardwareList.join(",");
    //       }
    //       // this.caseDataInfo.wisdomLabelsChildren = [
    //       //   ...new Set(this.caseDataInfo.wisdomLabelsChildren),
    //       // ];
    //       // this.caseDataInfo.labelsChildren = [
    //       //   ...new Set(this.caseDataInfo.labelsChildren),
    //       // ];
    //       // this.caseDataInfo.sectorssChildren = [
    //       //   ...new Set(this.caseDataInfo.sectorssChildren),
    //       // ];

    //       if (this.projectDataType) {
    //         res = await updateCaseData(this.caseDataInfo);
    //       } else {
    //         res = await addCaseData(this.caseDataInfo);
    //       }

    //       if (res.code === 200) {
    //         this.$message.success(
    //           `${this.projectDataType ? "编辑" : "新增"}成功`
    //         );

    //         this.$router.go(-1);
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     }
    //   });
    // },
    // 取消
    // cancelItem() {
    //   this.$confirm("确认退出吗 ?", "提示")
    //     .then(() => {
    //       this.$router.go(-1);
    //     })
    //     .catch(() => {});
    // },
    searchCountry(val) {
      this.caseDataInfo.planCountry = val;
    },
    searchProvince(val) {
      this.caseDataInfo.planProvince = val;
    },
    searchCity(val) {
      this.caseDataInfo.planCity = val;
    },

    // remoteMethod1(query) {
    //   if (query !== "") {
    //     this.loading = true;
    //     setTimeout(async () => {
    //       this.loading = false;
    //       const reportName = query;
    //       const res = await findReport({ reportName: reportName });
    //       this.options1 = res.data;
    //     }, 200);
    //   } else {
    //     this.options1 = [];
    //   }
    // },
    // async changeCompany(item1) {
    //   const res = await findPlan({ companyCode: item1 });
    //   if (res.code == 200) {
    //     this.optionsAssociation = res.data;
    //   }
    // },

    // ==================================
  },
  mounted() {
    document.getElementsByClassName("caseDataInfo")[0].style.minHeight =
      document.body.clientHeight - 280 + "px";
  },
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: hidden;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  overflow: auto;
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  // width: 150px !important;
  // height: 150px !important;
  // line-height: 150px !important;
  // width: 135px !important;
  // height: 92px !important;
  // line-height: 92px !important;
  // background: #ededed !important;
  width: 76px !important;
  height: 76px !important;
  line-height: 76px !important;
}
// /deep/ .el-upload-list--picture-card .el-upload-list__item {
//   width: 135px !important;
//   height: 92px !important;
//   line-height: 92px !important;
//   background: #ededed !important;
// }
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }

  .cover {
    display: flex;
    flex-direction: column;
  }
  .thumbnail {
    display: flex;
    color: #bebebe;
  }
  .thumbnail img {
    width: 143.23px;
    height: 81.63px;
    border-radius: 4px;
  }
  .thumbnail video {
    width: 160.28px;
    height: 91.35px;
    border-radius: 4px;
  }

  .thumbnail div {
    margin-right: 20px;
  }
  .thumbnail_box div {
    display: flex;
    flex-direction: column;
    height: 30px;
  }
}
// =============
.listData {
  display: flex;
}
.upload-demo {
  position: absolute;
  right: -70px;
}
</style>
