<template>
  <div>
    <div class="relatedInformation">
      <div class="infoOne" v-if="isShow">
        <el-form
          :model="caseDataInfo"
          :rules="informationRules"
          ref="informationForm"
          label-width="120px"
        >
          <!-- reportName研究报告名称  reportId研究报告id-->
          <el-form-item label="研究报告:" prop="reportId">
            <el-select
              v-model="caseDataInfo.reportId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod1"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
              @change="changeReportName"
            >
              <el-option
                v-for="(item, index) in options1"
                :key="index"
                :label="item.reportName"
                :value="item.reportId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属企业:">
            <el-select
              v-model="caseDataInfo.companyServiceId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethodCompanyName"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
              @change="syncServiceCompanyName"
            >
              <el-option
                v-for="(item, index) in serviceCompanyoptions"
                :key="index"
                :label="item.companyFullName"
                :value="item.companyCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业简称:" prop="serviceCompanyName">
            <el-input
              v-model="caseDataInfo.serviceCompanyName"
              style="width: 40%"
              readonly
              class="noBorder"
            />
          </el-form-item>
          <el-form-item label="关联产品:">
            <el-select
              v-model="caseDataInfo.productId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="getProductByName"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in productIdOptions"
                :key="index"
                :label="item.productName"
                :value="item.productCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类型:">
            <el-radio-group v-model="caseDataInfo.productType">
              <el-radio
                v-for="(item, index) in query.judgeArray"
                :key="index"
                :label="item.label"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联场景:">
            <el-select
              v-model="caseDataInfo.sceneId"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethodScene"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in scenarioNameOptions"
                :key="index"
                :label="item.sceneName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联方案:">
            <el-select
              v-model="caseDataInfo.association"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethodSolutionName"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in solutionNameOptions"
                :key="index"
                :label="item.solutionName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- clientCompanyName智参企业 -->
          <el-form-item label="智参企业:">
            <el-select
              v-model="caseDataInfo.companyCode"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethodZcCompanyName"
              :loading="loading"
              :loading-text="loadingText"
              style="width: 40%"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in zcCompanyNameOptions"
                :key="index"
                :label="item.companyFullName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- ----------------分割线------------- -->
          <!-- <el-form-item label="案例网址：">
            <el-input
              v-model="caseDataInfo.planInformation"
              class="lager-input"
            />
            <el-link
              :href="caseDataInfo.planInformation"
              target="_blank"
              :underline="false"
              >{{ caseDataInfo.planInformation }}</el-link
            >
            <i
              class="jr-iconjr-icon-edit1"
              style="margin-left: 24px"
              @click="isLinkShow = true"
            ></i>
            <div class="input_button" v-if="isLinkShow">
              <el-input
                v-model="caseDataInfo.planInformation"
                style="width: 470px"
              />
              <el-button
                type="primary"
                style="height: 40px; margin-left: 24px"
                @click="isLinkShow = false"
                >保存</el-button
              >
            </div>
          </el-form-item> -->
        </el-form>
        <div class="ess_people" v-if="projectDataType">
          <div class="ess_people_list">
            创建人：{{ caseDataInfo.createName }}
          </div>
          <div class="ess_people_list">修改人：{{ caseDataInfo.modified }}</div>
          <div class="ess_people_list">
            创建时间：{{ caseDataInfo.createTime }}
          </div>
          <div class="ess_people_list">
            修改时间：{{ caseDataInfo.modification }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpLoadImage from "../Product/UpLoadImage";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
//  查询研究报告 /  查询所属企业 / 关联产品查询 / 关联场景查询 / 关联方案查询 / 智参企业查询
import {
  addCaseData,
  updateCaseData,
  findScheme,
  findReport,
  findCompany,
  findPlan,
  getProductByName,
  getByScenarioName,
  getBySolutionName,
  getZcByCompanyName,
} from "@/api/caseData";
import CascaderArea from "@/components/CascaderArea.vue";
import DataTime from "@/components/DataTime.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
export default {
  name: "RelatedInformation",
  components: {
    CascaderArea,
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
  },
  props: {
    caseDataInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isLinkShow: false,
      isShow: true,
      optionsAssociation: [],
      // inputVisible: false,
      // inputValue: "",
      // imageList: [],
      options: [],
      options1: [], //研究报告
      serviceCompanyoptions: [], //所属企业数据
      productIdOptions: [], //关联产品数据
      scenarioNameOptions: [], //关联场景数据
      solutionNameOptions: [], //关联方案数据
      zcCompanyNameOptions: [], //智参企业搜索出来的关键词
      informationRules: {
        reportName: [
          { required: false, message: "请输入研究报告", trigger: "blur" },
        ],
      },
      query: {
        judgeArray: [
          {
            label: "软件",
          },
          {
            label: "软硬件",
          },
        ],
      },
      loading: false,
      loadingText: "加载中...", //默认提示
    };
  },

  computed: {
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    editInfo() {
      return Number(this.$route.query.editInfo);
    },
  },
  watch: {
    // "caseDataInfo.id": {
    //   handler(newVal) {
    //     if (this.caseDataInfo && newVal) {
    //       setTimeout(() => {
    //         this.isShow = true;
    //       }, 200);
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
    // 监听所属企业，所属企业被清空，也将企业简称清空
    "caseDataInfo.companyServiceId": {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.caseDataInfo.serviceCompanyName = "";
        }
      },
    },
  },

  created() {
    // if (this.projectDataType) {
    //   // this.search();
    //   this.findScheme();
    //   setTimeout(() => {
    //     this.isShow = true;
    //   }, 500);
    // } else {
    //   this.isShow = true;
    // }
  },
  methods: {
    // 研究报告查询
    remoteMethod1(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          this.loading = false;
          const reportName = query;
          const res = await findReport({ reportName: reportName });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.options1 = res.data;
        }, 200);
      } else {
        this.options1 = [];
      }
    },
    // 所属企业查询
    remoteMethodCompanyName(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          const companyName = query;
          const res = await findCompany({ companyName: companyName });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.serviceCompanyoptions = res.data;
          this.loading = false;
        }, 200);
      } else {
        this.serviceCompanyoptions = [];
      }
    },
    // 根据所属企业来回显企业简称
    syncServiceCompanyName(val) {
      this.serviceCompanyoptions.forEach((item) => {
        if (item.companyCode == val) {
          this.caseDataInfo.serviceCompanyName = item.companyName;
          // this.caseDataInfo.companyCode = item.companyCode;
        }
      });
    },
    // 关联产品查询
    getProductByName(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          this.loading = false;
          const productName = query;
          const res = await getProductByName({ productName: productName });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.productIdOptions = res.data;
        }, 200);
      } else {
        this.productIdOptions = [];
      }
    },
    // 关联场景查询
    remoteMethodScene(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          this.loading = false;
          const scenarioName = query;
          const res = await getByScenarioName({ scenarioName: scenarioName });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.scenarioNameOptions = res.data;
        }, 200);
      } else {
        this.scenarioNameOptions = [];
      }
    },
    // 关联方案查询
    remoteMethodSolutionName(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          this.loading = false;
          const solutionName = query;
          const res = await getBySolutionName({ solutionName: solutionName });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.solutionNameOptions = res.data;
        }, 200);
      } else {
        this.solutionNameOptions = [];
      }
    },
    // 智参企业查询
    remoteMethodZcCompanyName(query) {
      if (query !== "") {
        this.loading = true;
        this.loadingText = "加载中...";
        setTimeout(async () => {
          this.loading = false;
          const companyFullName = query;
          const res = await getZcByCompanyName({
            companyFullName: companyFullName,
          });
          if (res.code != 200) {
            this.loadingText = "加载中...";
          }
          this.zcCompanyNameOptions = res.data;
        }, 200);
      } else {
        this.zcCompanyNameOptions = [];
      }
    },
    //
    changeReportName(val) {
      // console.log(val, "val");
    },

    // ----------------------------------------------
    // async findScheme() {
    //   const res = await findScheme({ planId: this.editInfo });
    //   this.caseDataInfo = res.data;

    //   if (res.data.reportId && res.data.reportName) {
    //     this.options1 = [
    //       {
    //         reportId: res.data.reportId,
    //         reportName: res.data.reportName,
    //       },
    //     ];
    //   }
    //   if (this.caseDataInfo.association && this.caseDataInfo.solutionName) {
    //     this.caseDataInfo.association = Number(this.caseDataInfo.association);
    //     this.optionsAssociation = [
    //       {
    //         id: this.caseDataInfo.association,
    //         solutionName: this.caseDataInfo.solutionName,
    //       },
    //     ];
    //   }

    //   this.caseDataInfo.imgUrl = this.formatString(this.caseDataInfo.imgUrl);
    //   this.caseDataInfo.simgpathImg = this.formatString(
    //     this.caseDataInfo.imgpath
    //   );
    // },
    // saveClick(val) {
    //   this.caseDataInfo.simgpathImg = val;
    // },
    // showInput() {
    //   this.inputVisible = true;
    //   this.$nextTick((_) => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    // },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
  },
  mounted() {
    document.getElementsByClassName("el-tabs__content")[0].style.minHeight =
      document.body.clientHeight - 262 + "px";
  },
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: hidden;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  overflow: auto;
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  width: 150px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 150px !important;
  height: 150px !important;
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
.noBorder {
  /deep/.el-input__inner {
    border: none !important;
  }
}
</style>
